<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>资产管理</BreadcrumbItem>
                <BreadcrumbItem>资产使用</BreadcrumbItem>
                <BreadcrumbItem>保养单</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="keywords" placeholder="单号/保养人" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <a v-if="permissionButton.view" @click="view(row, index)">查看</a>
                    </template>
                </Table>
                <viewMaintain v-model="viewMaintainShow" :maintainId="maintainId" v-on:getMaintainPage="getMaintainPage"></viewMaintain>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize"
                              :total="total"
                              :current="pageNum"
                              :page-size-opts="[15,20,50,100]"
                              @on-change="changePage"
                              @on-page-size-change="changPageSize"
                              show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission';
import viewMaintain from '../maintain/view';
import { reqGetMaintainPage } from '../../../../api/asset-api';

export default {
    name: 'maintainList',
    components: {
        viewMaintain,
    },
    data() {
        return {
            filter: {},
            columns: [{
                    title: '保养单号',
                    key: 'orderNumber',
                    tooltip: 'ture',
                    minWidth: 200
                },
                {
                    title: '保养人',
                    key: 'handlerName',
                    tooltip: 'ture',
                    width: 150
                },
                {
                    title: '保养金额（元）',
                    key: 'amount',
                    tooltip: 'ture',
                    width: 150
                },
                {
                    title: '保养时间',
                    tooltip: 'ture',
                    width: 160,
                    render: (h, params) => {
                        return h('span', params.row.maintainTime === null ? '' : this.$moment(params.row.maintainTime).format('YYYY-MM-DD'));
                    }
                },
                {
                    title: '资产数量',
                    key: 'quantity',
                    tooltip: 'ture',
                    width: 100
                },
                {
                    title: '保养单状态',
                    tooltip: 'ture',
                    width: 120,
                    render: (h, params) => {
                        return h('span', [
                            h('span',{
                                style:{
                                    color: params.row.maintainStatus === '保养中' ? '#ff9900' : '#19be6b'
                                }
                            },params.row.maintainStatus)
                        ]);
                    }
                },
                {
                    title: '审批状态',
                    tooltip: 'ture',
                    width: 100,
                    render: (h, params) => {
                        return h('span', [
                            h('span',{
                                style:{
                                    color: params.row.approvalStatus === 0 ? '#ff9900' : (params.row.approvalStatus === 1 ? '#ed4014' : '#19be6b')
                                }
                            },params.row.approvalStatus === 0 ? '审批中' : (params.row.approvalStatus === 1 ? '驳回' : '通过'))
                        ]);
                    }
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 100
                }
            ],
            dataList: [],
            maintainId: '',
            loading: false,
            keywords: '',
            viewMaintainShow: false,
            permissionButton: {
                add: false,
                edit: false,
                del: false,
                view: false,
            },
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 20
        }
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getMaintainPage();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getMaintainPage();
        },
        //获取分页列表
        doSearching(){
            this.pageNum = 1;
            this.getMaintainPage();
        },
        getMaintainPage() {
            let that = this;
            that.loading = true;
            that.dataList = [];
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                keywords: that.keywords,
            };
            reqGetMaintainPage(para).then((res) => {
                that.loading = false;
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch(() => {
                that.loading = false;
                that.dataList = [];
            });
        },
        view(row, index){
            this.maintainId = row.id;
            this.viewMaintainShow = true;
        }
    },
    mounted() {
        this.getMaintainPage();
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
    }
}
</script>
